.header {
  justify-content: space-between;
  align-items: center;
  padding-inline: 0 1rem;
}
ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
}

.navContainer {
  padding: 0.8rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.4rem;
  background-color: var(--secondary-color);
  border-radius: 1.6rem;
}
.navContainer > li > a {
  color: #000;
  padding: 0.4rem 0.8rem;
  border: 2px solid rgba(30, 29, 29, 0);
}

.navActive {
  border: 2px solid rgb(30, 29, 29) !important;
  border-radius: 1.6rem;
}

.mobileNavContainer {
  flex-direction: column;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 0.5s;
  transform: translateX(13rem);
  gap: 1rem;
  background: #000;
  width: 50%;
  height: 100%;
  padding: 1rem;
  padding-top: 3rem;
  margin: 0;
  z-index: 3;
}
.mobileNavContainer > ul > li {
  color: #fff;
  padding: 0.4rem 0.8rem;
  border: 2px solid rgba(30, 29, 29, 0);
}
.toggle {
  overflow: hidden;
  width: 45px;
  height: 40px;
  background-color: var(--secondary-color);
  border-radius: 7px;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
}
.toggle span {
  width: 26px;
  height: 4px;
  background-color: #000;
  border-radius: 3px;
  transition: 0.5s;
}

.toggle span:after,
.toggle span:before {
  content: "";
  position: absolute;
  height: 4px;
  background-color: #000;
  border-radius: 3px;
}
.toggle span::before {
  width: 15px;
  transition: 0.5s;
  transform: translateY(-10px);
}
.toggle span:after {
  width: 10px;
  transition: 0.5s;
  transform: translateY(10px);
}
.toggle.active {
  position: fixed;
  right: 0.8rem;
  top: 2.4rem;
}
.toggle.active span:before {
  transform: translateX(60px);
}
.toggle.active span {
  transform: rotate(45deg);
}
.toggle.active span:after {
  width: 25px;
  transform: rotate(90deg);
}
.showMobileNav {
  transform: translateX(0);
}

@media screen and (max-width: 400px) {
  .mobileNavContainer,
  .toggle {
    display: flex;
  }
  .navContainer {
    display: none;
  }
  .logoText {
    font-size: 2rem !important;
  }
}

.cropContainer {
  display: flex;
  align-items: center;
}
.cropContainer h1 {
  /* font-family: Zapfino; */
  font-size: 2.5rem;
  margin: 0;
}
.crop {
  width: 125px;
  height: 120px;
  transform: scale(0.5);
}

.crop img {
  width: 125px;
  object-fit: cover;
}
