:root {
  /* --primary-color: #73245f; */
  --secondary-color: #9baed8;
  --primary-white: #ffffff;
  --light-color: #a7448e;
  --primary-color: var(--light-color);
  --green: #1d4734;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  width: 100vw;
  background: var(--primary-color);
}
.view-port-height {
  height: 100vh;
}
.view-port-width {
  width: 100vw;
}

.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}

.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.p-l-24 {
  padding-left: 2.4rem;
}
.p-l-2 {
  padding-left: 2rem;
}
.p-l-1{
  padding-left: 1rem;
}
.p-r-20 {
  padding-right: 2rem;
}
.p-inline-24 {
  padding-inline: 2.4rem;
}
.p-b-12 {
  padding-bottom: 1.2rem;
}
.p-inline-20 {
  padding-inline: 2rem;
}

.object-fit-contain {
  object-fit: contain;
}

.bg-primary {
  background: var(--primary-color);
}

.height-100 {
  height: 100vh;
}
