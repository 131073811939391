@font-face {
  font-family: Proxima Nova;
  src: url("assets/fonts/Proxima\ Nova\ Font.otf");
  font-display: font-swap;
}
@font-face {
  font-family: Zapfino;
  src: url("assets/fonts/ZapfinoExtraLT-One.otf");
  font-display: font-swap;
}
* {
  font-family: Proxima Nova;
}
